/* Organizing Properties */
/* by Kevin Powel */
/* https://www.youtube.com/watch?v=3Y03OSNw6zo */

/* display */
/* positioning */
/* box-model */
/* typography*/
/* manipulating */
/* miscellaneous */

@import url(base.css);
@import url(layout.css);

/*Components*/
@import url(header.css);
@import url(navbar.css);
@import url(hubBoard.css);
@import url(card.css);

@import url(/node_modules/react-grid-layout/css/styles.css);
@import url(/node_modules/react-resizable/css/styles.css);

.react-grid-layout {
  position: relative;
  transition: height 0ms ease;
}
.react-grid-item {
  transition: all 0ms ease;
  transition-property: left, top;
}
