/* Header Module */
/* Mobile Screen (Portrait) Rules <576px */

/* @media (max-width: 768px)  */

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) and (-webkit-min-device-pixel-ratio: 1) {
  .header-box {
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    background: white;
    box-shadow: 0 3px 10px -4px rgb(128 183 190);
  }

  .header-wrapper {
    width: clamp(16rem, 85vw, 75rem);
    margin-left: auto;
    margin-right: auto;
    padding-left: clamp(1.09rem, 1rem + 0.47vw, 1.33rem);
    padding-right: clamp(1.09rem, 1rem + 0.47vw, 1.33rem);
  }
  .header-flexRow {
    justify-content: space-between;
    align-items: center;
  }

  .header--logo {
    width: 280px;
    aspect-ratio: 4.84/1;
    margin-right: auto;
  }

  .header--searchForm {
    max-width: 260px;
    display: flex;
    margin: 0.3rem auto 0.3rem;
  }

  .header--searchForm button {
    outline: none;
    border: none;
    padding: 0px 15px;
    border-radius: 0 0.35em 0.35em 0;
    cursor: pointer;
  }
}

/* Large Screen Rules >= 992px */

@media (min-width: 992px) {
  .header-box {
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    background: white;
    box-shadow: 0 3px 10px -4px rgb(128 183 190);
  }

  .header-wrapper {
    width: clamp(16rem, 85vw, 75rem);
    margin-left: auto;
    margin-right: auto;
    padding-left: clamp(1.09rem, 1rem + 0.47vw, 1.33rem);
    padding-right: clamp(1.09rem, 1rem + 0.47vw, 1.33rem);
  }
  .header-flexRow {
    justify-content: space-between;
    align-items: center;
  }

  .header--logo {
    width: 280px;
    aspect-ratio: 4.84/1;
    margin-right: auto;
  }

  .header--searchForm {
    max-width: 260px;
    display: flex;
    margin: 0.3rem auto 0.3rem;
  }

  .header--searchForm button {
    outline: none;
    border: none;
    padding: 0px 15px;
    border-radius: 0 0.35em 0.35em 0;
    cursor: pointer;
  }
}
