/* Navbar Module */
/* Mobile Screen (Portrait) Rules <576px */

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 991px) 
  and (-webkit-min-device-pixel-ratio: 1) {
  .navbar-wrapper {
    justify-content: space-between;
    align-items: center;
    width: clamp(16rem, 85vw, 75rem);
    margin-left: auto;
    margin-right: auto;
    padding-left: clamp(1.09rem, 1rem + 0.47vw, 1.33rem);
    padding-right: clamp(1.09rem, 1rem + 0.47vw, 1.33rem);
  }
  .navbar--logo {
    width: 484px;
    height: 100px;
  }
  .navbar--menu {
    /* display */
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: space-evenly;
    /* positioning */
    position: static;
    /* box-model */
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.85rem;
  
    font-family: var(--ff-subheading);
    font-weight: 400;
  }
  .navbar--menu > li {
    align-self: center;
    text-align: center;
    list-style-type: none;
    width: clamp(12rem, 12.5vw, 29rem) ;
  }
  .navbar--menu > li > a {
    color: #767676;
    text-decoration: none;
    white-space: nowrap;
  }
}

/* Large Screen Rules >= 992px */

@media (min-width: 992px) {
  .navbar-wrapper {
    justify-content: space-between;
    align-items: center;
    width: clamp(16rem, 85vw, 75rem);
    margin-left: auto;
    margin-right: auto;
    padding-left: clamp(1.09rem, 1rem + 0.47vw, 1.33rem);
    padding-right: clamp(1.09rem, 1rem + 0.47vw, 1.33rem);
  }
  .navbar--logo {
    width: 484px;
    height: 100px;
  }
  .navbar--menu {
    /* display */
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: space-evenly;
    /* positioning */
    position: static;
    /* box-model */
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.85rem;

    font-family: var(--ff-subheading);
    font-weight: 400;
  }
  .navbar--menu > li {
    align-self: center;
    text-align: center;
    list-style-type: none;
    width: clamp(12rem, 12.5vw, 29rem) ;
  }
  .navbar--menu > li > a {
    color: #767676;
    text-decoration: none;
    white-space: nowrap;
  }
}
