@media only screen and (min-device-width: 768px) and (max-device-width: 991px) and (-webkit-min-device-pixel-ratio: 1) {
  .hubBoard-box {
    width: 100%;
    background: rgb(215, 234, 231);
  }

  .hubBoard--title {
    padding: 1.15rem;
    font-family: var(--ff-subheading);
    font-weight: 700;
    text-align: center;
  }
}
/* Large Screen Rules >= 992px */

@media (min-width: 992px) {
  .hubBoard-box {
    width: 100%;
    background: rgb(215, 234, 231);
  }

  .hubBoard--title {
    padding: 1.15rem;
    font-family: var(--ff-subheading);
    font-weight: 700;
    text-align: center;
  }
}
